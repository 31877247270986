import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { BarLoader } from 'react-spinners';
import Box from '@basecomponents/Box';
import { ThemeContext } from 'styled-components';

/**
 * @category BaseComponents
 * @param {string} barColor
 * @returns {React.FC}
 */
const CustomSpinner = ({ barColor, ...props }) => {
  const { defaultStyle = {} } = useContext(ThemeContext);
  const { customSpinnerBox = {}, barLoader = {} } =
    defaultStyle.defaultCustomSpinner(barColor);
  return (
    <Box {...customSpinnerBox} {...props}>
      <BarLoader {...barLoader} />
    </Box>
  );
};

CustomSpinner.propTypes = {
  barColor: PropTypes.string.isRequired,
};

export default CustomSpinner;
