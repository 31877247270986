/* eslint-disable prefer-destructuring, sort-keys */

import { darken, lighten, linearGradient } from 'polished';
import { get } from 'lodash';
import config from '@src/config.json';
import themeVariantToValue from '../utilities/theme-variant-to-value';
/* base colors */
/* mono */
const white = '#fefefe';
const whiteMoreTransparent = 'rgba(255, 255, 255, 0.1)';
const whiteTransparent = 'rgba(255, 255, 255, 0.4)';
const transparent = 'rgba(0, 0, 0, 0)';
const black = '#090909';

/* colors */
const red = '#FF0000';
const orange = '#FF7F00';
const yellow = '#FFFF00';
const green = '#00FF00';
const blue = '#0000FF';
const purple = '#4B0082';
const violet = '#EE82EE';
const lightYellow = '#E6FE73';

const defaultTheme = (mga, isHighContrastOn) => {
  const defaultStyle = {};
  let basePrimary = '';
  let baseAccent = '';
  let baseAccentSecondary = '';
  let baseAccentPrimary='';
  let baseAccentSecondaryLighter='';
  let scheme = mga;
  if (isHighContrastOn) {
    scheme += '_HC';
  }

  switch (scheme) {
    case 'PPI':
      basePrimary = '#005091';
      baseAccent = '#00ADB7';
      baseAccentSecondary = '#F7B108';
      break;
    case 'PPI_HC':
      basePrimary = '#005091';
      baseAccent = '#006166';
      baseAccentSecondary = '#715004';
      break;
    case 'FIGO':
      basePrimary = '#22435E';
      baseAccent = '#73DAD2';
      baseAccentSecondary = '#3D484D';
      break;
    case 'FIGO_HC':
      basePrimary = '#22435E';
      baseAccent = '#1A615B';
      baseAccentSecondary = '#3D484D';
      break;
    case 'PTZ_SODACO':
      basePrimary = '#01313D';
      baseAccent = '#02596f';
      baseAccentSecondary = '#95E6E2';
      baseAccentPrimary = '#F05E4D';
      baseAccentSecondaryLighter = '#D5F5F3';
      break;
    case 'default_HC':
      basePrimary = '#11565C';
      baseAccent = '#355F58';
      baseAccentSecondary = '#AB2117';
      break;
    default:
      basePrimary = config.canadaEnv ? '#01313D' :'#11565C';
      baseAccent = config.canadaEnv ? '#02596f' : '#6AAFA1';
      baseAccentSecondary = config.canadaEnv ? '#95E6E2' : '#E9675D';
      baseAccentPrimary = config.canadaEnv ? '#F05E4D' : '';
      baseAccentSecondaryLighter = config.canadaEnv ? '#D5F5F3' : '';
      break;
  }
  /* theme colors */
  const MGA = mga;
  const primary = basePrimary;
  const primaryDark = darken(0.1, primary);
  const primaryLight = lighten(0.1, primary);

  const accent = baseAccent;
  const accentDark = darken(0.1, accent);
  const accentLight = lighten(0.1, accent);

  const accentPrimary = baseAccentPrimary;
  const accentSecondary = baseAccentSecondary;
  const accentSecondaryDark = darken(0.1, accentSecondary);
  const accentSecondaryLight = lighten(0.1, accentSecondary);
  const accentSecondaryLighter = baseAccentSecondaryLighter;

  const gray0 = lighten(0.4, black);
  const gray1 = lighten(0.5, black);
  const gray2 = lighten(0.6, black);
  const gray3 = lighten(0.7, black);
  const gray4 = lighten(0.8, black);
  const gray5 = lighten(0.9, black);

  const textHeading = accentDark;
  const textLink = config.canadaEnv ? accentPrimary : accentSecondary;
  const textPrimary = black;
  const textSubtle = lighten(0.1, black);
  const textSubtleSecondary = whiteTransparent;

  const colors = {
    lightYellow,
    primaryDark,
    primary,
    primaryLight,
    accentDark,
    accent,
    accentLight,
    accentSecondaryDark,
    accentSecondary,
    accentSecondaryLight,
    accentSecondaryLighter,
    gray: [gray0, gray1, gray2, gray3, gray4, gray5],
    basic: {
      red,
      orange,
      yellow,
      green,
      blue,
      purple,
      violet,
    },
    black,
    body: white,
    border: primaryLight,
    borderSecondary: whiteMoreTransparent,
    error: isHighContrastOn ? darken(0.2, red) : red,
    errorDark: darken(0.2, red),
    success: green,
    successDark: darken(0.1, green),
    text: {
      heading: textHeading,
      link: textLink,
      primary: textPrimary,
      subtle: textSubtle,
      subtleSecondary: textSubtleSecondary,
    },
    visualizations: [
      blue,
      green,
      orange,
      red,
      yellow,
      purple,
      darken(0.1, blue),
      darken(0.1, green),
      darken(0.1, orange),
      darken(0.1, yellow),
      darken(0.1, red),
      darken(0.1, purple),
      lighten(0.1, blue),
      lighten(0.1, green),
      lighten(0.1, orange),
      lighten(0.1, yellow),
      lighten(0.1, red),
      lighten(0.1, purple),
    ],
    warning: orange,
    white,
    transparent,
  };

  /* Gradients */
  /* base gradients */
  const baseGradients = {
    sidebar: linearGradient({
      colorStops: [`${white} 0%`, `${primary} 100%`],
      fallback: white,
      toDirection: 'to bottom',
    }),
    listItem: linearGradient({
      colorStops: [`${white} 0%`, `${gray4} 50%`, `${white} 100%`],
      fallback: white,
      toDirection: 'to right',
    }),
  };

  /* theme gradients */
  const gradients = {
    sidebar: baseGradients.sidebar,
    listItemGradient: baseGradients.listItem,
  };

  /* breakpoints */

  const breakpoints = ['300px', '500px', '720px', '1280px', '1600px'];
  breakpoints.xs = breakpoints[0];
  breakpoints.sm = breakpoints[1];
  breakpoints.md = breakpoints[2];
  breakpoints.lg = breakpoints[3];
  breakpoints.xl = breakpoints[4];

  /* typography */
  const baseFontSizesForBreakpoint = [
    '3.5vw',
    '3.5vw',
    '2.2vw',
    '15px',
    '15px',
  ];

  const fonts = {
    mono: 'Menlo, monospace',
    primary: 'Poppins, Roboto, sans-serif',
    secondary: 'Poppins, Roboto, sans-serif',
  };

  const fontSizeScale = 1.25;

  const fontSizes = [
    `${1 / fontSizeScale / fontSizeScale / fontSizeScale}rem`,
    `${1 / fontSizeScale / fontSizeScale}rem`,
    `${1 / fontSizeScale}rem`,
    '1rem',
    `${fontSizeScale}rem`,
    `${fontSizeScale * fontSizeScale}rem`,
    `${fontSizeScale * fontSizeScale * fontSizeScale}rem`,
    `${fontSizeScale * fontSizeScale * fontSizeScale * fontSizeScale}rem`,
  ];

  const fontWeights = {
    bold: 600,
    light: 100,
    normal: 400,
    xbold: 900,
  };

  const letterSpacings = ['0.05em', '0.1em', '0.125em'];

  const lineHeights = ['1.2em', '1.4em', '1.6em'];

  /* space */
  const space = [
    '0',
    '0.25rem',
    '0.375rem',
    '0.5rem',
    '0.75rem',
    '1.25rem',
    '2rem',
    '3.25rem',
    '5.25rem',
    '8.5rem',
  ];

  /* sizes */
  const sizes = {
    heights: {
      header: '5rem',
      headerMargin: `calc(5rem + 20px)`,
      input: `calc(${lineHeights[2]} + ${space[4]} * 2)`,
      footer: '40px',
      content: 'calc(100vh - 40px)',
    },
    maxWidths: {
      content: '1168px',
      form: '500px',
      largeForm: '800px',
      smallForm: '380px',
    },
    widths: {
      sidebar: config.canadaEnv ? '280px' : '273px',
      // sidebarMin: '80px',
      sidebarMin: '5.3rem',
      sidebarMargin: '100px',
      submitButton: '14rem',
      Input: '100%',
      InputMasked: '100%',
    },
  };

  /* border radii */
  const radii = ['2px', '5px', '8px'];

  /* box shadows */
  const shadows = [
    '0 3px 10px 0 rgba(164, 174, 185, 0.1)',
    '0 10px 25px rgba(164, 174, 185, 0.2)',
    '0 20px 50px rgba(11, 34, 56, 0.3)',
    '0 20px 60px rgba(0, 0, 0, 0.4)',
    '-20px 0px 60px rgba(0, 0, 0, 0.4)',
    '2px 2px 10px rgba(0, 0, 0, 0.4)',
    '-2px 2px 10px rgba(0, 0, 0, 0.4)',
    '0px 5px 10px rgba(0, 0, 0, 0.4)',
  ];

  /* z indexes */
  const zIndices = [1, 2, 3, 4, 5];

  /* variants */
  const button = {
    '&:hover': {
      bg: 'accentLight',
      transition: '0.5s ease-in-out',
      boxShadow: 5,
    },
    bg: 'accent',
    borderColor: 'transparent',
    borderRadius: 1,
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: 1,
    color: 'white',
    fontSize: 2,
    fontWeight: 'bold',
    height: 'heights.input',
    letterSpacing: 0,
    px: 7,
    py: 4,
    textTransform: 'uppercase',
    transition:
      'background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.3s ease-in-out',
  };

  const transparentButton = {
    ...button,
    '&:hover': { boxShadow: 1 },
    boxShadow: 0,
    bg: 'transparent',
    color: 'primary',
  };

  const variants = {
    buttons: {
      cancel: {
        ...transparentButton,
        '&:hover': { bg: 'errorDark', borderColor: 'error', color: 'white' },
        bg: 'error',
        color: 'white',
      },
      link: {
        '&:hover': { textDecoration: 'underline' },
        py: 2,
        textAlign: 'left',
        textDecoration: 'none',
      },
      primary: { ...button },
      secondary: {
        ...button,
        '&:hover': { bg: 'accentSecondaryDark' },
        bg: 'accentSecondary',
      },
      tertiary: { ...button, '&:hover': { bg: 'primaryDark' }, bg: 'primary' },
      transparent: transparentButton,
      submit: { ...button, bg: 'purple' },
    },
    inputs: {
      primary: {
        '&::placeholder': { color: 'accent' },
        '&:focus': { borderColor: 'primary' },
        bg: 'white',
        borderColor: 'accent',
        borderRadius: 1,
        borderStyle: 'solid',
        borderWidth: '2px',
        color: 'text.primary',
        px: 4,
        py: 4,
      },
    },
  };

  // eslint-disable-next-line no-unused-vars
  defaultStyle.defaultInput = (error, disabled) => {
    return {
      borderColor: error ? 'error' : null,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };
  };

  defaultStyle.defaultInputMaxLengthStyle = {
    px: 5,
    fontSize: 1,
    fontWeight: 'bold',
    justifyContent: 'flex-end',
    display: 'flex',
  };

  defaultStyle.defaultInputMasked = (error, disabled) => {
    if (error) {
      return {
        borderColor: 'error',
      };
    }
    if (disabled) {
      return {
        borderColor: 'gray.4',
        color: 'gray.4',
      };
    }
    return {
      borderColor: null,
    };
  };

  defaultStyle.defaultCard = (cardSx) => ({
    outerBox: { bg: 'white', borderRadius: 2, m: 5, p: 5, ...cardSx },
    titleBox: { textTransform: 'uppercase', mx: 4, my: 2 },
  });

  defaultStyle.defaultSpinner = {
    baseContainer: {
      m: '0 !important',
    },
    loaderColor: colors.accentSecondary,
  };

  defaultStyle.defaultTabCarousel = {
    boxShadow: 1,
  };

  defaultStyle.defaultWorkInProgress = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'light',
    height: '100%',
    justifyContent: 'center',
  };

  defaultStyle.defaultToolTip = (tooltipSx) => {
    return {
      bg: (p) => `${p.colors.accent}`,
      borderRadius: (p) => `${p.radii[1]}!important`,
      maxHeight: '10rem',
      maxWidth: '50ch',
      opacity: '1!important',
      p: 4,
      ...(tooltipSx || {}),
    };
  };

  defaultStyle.defaultTab = (current = false) => {
    return {
      tabContainer: {
        borderRadius: 2,
        m: 5,
        p: 5,
        backgroundColor: 'white',
      },
      tabHeaderLabelContainer: {
        ':hover': current
          ? {
              borderColor: 'primaryLight',
              color: 'primaryLight',
            }
          : {
              borderColor: 'accent',
              color: 'accent',
            },
        alignItems: 'center',
        borderBottomStyle: 'solid',
        borderColor: current ? 'primary' : 'white',
        borderWidth: 4,
        color: current ? 'primary' : 'accent',
        cursor: 'pointer',
        display: 'flex',
        fontWeight: current ? 'bold' : 'normal',
        textTransform: 'uppercase',
        transition: 'all 0.25s ease-in-out',
        px: 5,
        py: 1,
      },
      renderTab: {
        borderRadius: 2,
        m: 5,
        p: 5,
      },
    };
  };

  defaultStyle.defaultInputPassword = (error) => ({
    borderColor: error ? 'error' : null,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  });

  defaultStyle.defaultTableWrapper = (sx) => ({
    mt: 5,
    overflow: 'auto',
    ...sx,
  });

  defaultStyle.defaultAddEmployee = { py: 3 };

  defaultStyle.defaultAddGroupDocument = {
    childDiv: {
      alignItems: 'center',
      bg: 'white',
      borderRadius: 4,
      boxShadow: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 3,
    },
    parentDiv: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
    HTwo: {
      py: 3,
    },
  };

  defaultStyle.defaultActionDropDown = (sx = {}, item = {}) => ({
    outerBox: {
      mt: 3,
      pb: 4,
      pt: 4,
      borderRadius: 1,
      boxShadow: 2,
      overflow: 'hidden',
      ...sx,
    },
    innerBox: {
      opacity: item.isDisabled ? '0.3' : '1',
      pointerEvents: item.isDisabled ? 'none' : 'auto',
    },
  });

  defaultStyle.defaultDropDownMenu = (sx) => ({
    menuBox: {
      mt: 3,
      pb: 4,
      pt: 4,
      borderRadius: 1,
      boxShadow: 2,
      overflow: 'hidden',
      ...sx,
    },
    menuItemBox: {
      '&:hover': {
        background: colors.accent,
        color: colors.white,

        svg: {
          color: colors.white,
        },
      },
    },
  });

  defaultStyle.defaultCheckbox = (labelSx) => {
    return { m: 0, ml: 4, order: 2, ...labelSx };
  };

  defaultStyle.defaultRowKeyValuePair = (columnCount) => {
    return {
      containerBox: {
        alignItems: 'flex-start',
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
      },
      innerContainerBox: {
        width: ['100%', null, `calc(100%/${columnCount})`, null],
        p: 4,
      },
      keyLabelBox: {
        textTransform: 'uppercase',
        my: 1,
        color: 'primary',
        fontSize: 3,
      },
      valueLabelBox: {
        color: 'primaryDark',
        fontSize: 3,
        fontWeight: 'bold',
      },
      containerBoxNoData: {
        color: 'accent',
        display: 'table',
        fontSize: 6,
        fontWeight: 'bold',
        height: '200px',
        textAlign: 'center',
        width: '100%',
      },
      innerContainerNoData: {
        display: 'table-cell',
        verticalAlign: 'middle',
      },
    };
  };

  defaultStyle.defaultInputWrapper = (
    errorSx = {},
    wrapperSx = {},
    disabled = false,
    labelSx = {},
    inputWrapperSx = {},
    error = false
  ) => {
    const containerCommons = {
      fontSize: 2,
      mt: 1,
    };
    return {
      errorContainerBelow: {
        color: 'error',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...errorSx,
        ...containerCommons,
      },
      containerBelow: containerCommons,
      wrapperContainerBox: {
        '&:first-of-type': { mt: 0 },
        bg: disabled ? 'gray.4' : null,
        borderRadius: 3,
        flex: 1,
        mt: 6,
        opacity: disabled ? '0.8' : null,
        pointerEvents: disabled ? 'none' : null,
        position: 'relative',
        ...wrapperSx,
      },
      labelBox: {
        color: 'text.subtle',
        cursor: 'pointer',
        display: 'block',
        fontSize: 2,
        fontWeight: 'bold',
        letterSpacing: 0,
        mb: 1,
        textTransform: 'uppercase',
        ...labelSx,
      },
      inputContainerBox: { position: 'relative', ...inputWrapperSx },
      validationContainerBox: {
        alignItems: 'center',
        bg: 'white',
        color: `${error ? 'error' : 'success'}`,
        display: 'flex',
        height: 'calc(100% - 4px)',
        m: '2px',
        position: 'absolute',
        right: '0',
        top: '0',
      },
    };
  };

  defaultStyle.defaultHeaderRightContainer = {
    outerBox: { alignItems: 'center', display: 'flex', zIndex: 1 },
    innerBox: {
      alignItems: 'center',
      display: ['none', null, 'flex'],
    },
    displayBox: {
      display: ['flex', null, 'none'],
    },
  };

  defaultStyle.defaultToolbarButton = (
    buttonSx = {},
    iconOnlySx = {},
    bg = {},
    iconSx = {},
    iconOnly = ''
  ) => ({
    buttonStyle: {
      ml: iconOnly ? 0 : 2,
      pl: iconOnly ? 0 : 5,
      pr: iconOnly ? 0 : 5,
      ...buttonSx,
      '&:hover': { bg: lighten(0.1, `${get(colors, `${bg}`)}`) },
      ...iconOnlySx,
      bg,
    },
    iconStyle: {
      color: 'white',
      fill: 'white',
      height: '20px',
      mr: `${iconOnly ? '0' : '4'}`,
      width: '20px',
      ...iconSx,
    },
  });

  defaultStyle.defaultHeroBanner = (bgImage) => ({
    wrapperSx: {
      backgroundImage: `url(${bgImage})`,
      backgroundPosition: 'right',
      backgroundSize: 'cover',
      bg: 'primary',
      display: 'flex',
      flexDirection: ['column', null, null, null, 'row'],
      mb: [9, null, null, null, 7],
      p: 6,
      position: 'relative',
    },
    bannerContainerSx: {
      color: 'white',
      pr: 6,
      width: ['100%', null, null, null, '40%'],
    },
    bannerTitleSx: {
      color: 'white',
      fontSize: 5,
      mb: 5,
    },
    bannerContentSx: {},
    cardContainerSx: {
      bg: 'white',
      boxShadow: 5,
      display: 'flex',
      mb: [-9, null, null, -8],
      minHeight: '16rem',
      mt: [5, null, null, null, 0],
      width: ['100%', null, null, null, '60%'],
    },
    cardTitleSx: {
      color: 'primary',
      fontSize: 4,
      mb: 5,
    },
    cardRightContainerSx: {
      p: 5,
      width: ['100%', null, '50%'],
    },
    cardContentSx: {
      mb: 5,
    },
    buttonSx: {
      bg: '#f6861f',
      borderRadius: '4px',
      color: 'white',
      py: 2,
      width: '100%',
    },
    cardLeftContainerSx: {
      bg: '#e6e6e6',
      borderBottom: [
        '18rem solid transparent',
        null,
        null,
        '16rem solid transparent',
      ],
      borderLeft: '4rem solid',
      borderLeftColor: 'white',
      display: ['none', null, 'block'],
      width: '50%',
    },
  });

  defaultStyle.defaultProgressTracker = (slides, currentTab) => ({
    tabDetailsIndexSx: (index) => {
      let indexColor;
      if (index < Number(currentTab)) {
        indexColor = 'white';
      } else if (index === Number(currentTab)) {
        indexColor = 'primary';
      } else {
        indexColor = 'gray.1';
      }
      return {
        alignItems: 'center',
        bg: index < Number(currentTab) ? 'primary' : 'white',
        borderColor:
          index < Number(currentTab) || index === Number(currentTab)
            ? `${primary} !important`
            : `${colors.gray[1]} !important`,
        border: `solid 0.15rem`,
        borderRadius: '50%',
        color: indexColor,
        display: 'flex',
        height: '2rem',
        justifyContent: 'center',
        right: 0,
        transform: 'translate(0, -50%)',
        width: '2rem',
      };
    },
    wrapperSx: {
      bg: 'white',
      borderBottom: '1px solid',
      borderBottomColor: 'gray.2',
      boxShadow: 5,
      pt: 6,
      mb: 6,
      width: '100%',
    },
    progressBarSx: {
      borderRadius: 3,
      display: 'flex',
      justifyContent: 'center',
      maxHeight: '0.15rem',
      mb: 9,
      mt: 6,
      mx: 'auto',
      position: 'relative',
      width: ['60vw', null, '80vw'],
    },
    tabGroupWrapperSx: {
      display: 'flex',
    },
    tabDetailsWrapperSx: {
      position: 'relative',
    },
    tabDetailsTitleSx: (index) => {
      return {
        color: index === Number(currentTab) ? 'primary' : 'gray.1',
        fontWeight: index === Number(currentTab) && 'bold',
        fontSize: [1, 2, null, 3],
        position: 'absolute',
        textAlign: 'center',
        top: 6,
        transform: 'translate(-25%)',
      };
    },
    progressTrackerSx: (index) => {
      return {
        bg: index < Number(currentTab) ? 'primary' : 'gray.1',
        display: index === slides.length - 1 && 'none',
        width: [
          `calc(60vw/${slides.length})`,
          null,
          `calc(80vw/${slides.length})`,
        ],
      };
    },
  });

  defaultStyle.defaultProgressBar = (currentTab = {}, slides) => ({
    outerBox: {
      bg: `${lighten(0.2, `${colors.primary}`)}`,
      borderRadius: 3,
      display: 'flex',
      mx: 'auto',
      width: ['25rem', null, null, '50rem'],
    },
    innerBox: {
      borderRadius: 3,
      display: 'flex',
      height: '0.5rem',
      width: '100%',
    },
    slideBox: {
      bg: 'primary',
      borderRadius: 3,
      width: `calc(100%*(${Number(currentTab)}/${slides.length}))`,
    },
    stepBox: {
      color: 'primary',
      display: 'flex',
      fontSize: 5,
      fontWeight: 'bold',
      justifyContent: 'center',
      my: 4,
    },
  });

  defaultStyle.defaultCustomRoundSpinner = (barColor) => ({
    outerBox: {
      display: 'flex',
      justifyContent: 'center',
      py: 7,
      width: '100%',
    },
    puffLoader: {
      color: barColor,
      size: 20,
    },
  });

  defaultStyle.defaultPaginator = (currentPage = null, currentIndex = null) => {
    return {
      paginatorButtonNextAndPrevious: {
        '&:hover': { bg: lighten(0.05, `${colors.accentSecondary}`) },
        bg: 'accentSecondary',
        borderRadius: '50%',
        color: 'white',
        height: '2rem',
        justifyContent: 'center',
        mx: 2,
        p: 0,
        width: '2rem',
      },
      paginatorNumericButtons: {
        '&:hover': {
          bg: currentPage === currentIndex ? 'white' : 'accentLight',
          color: currentPage === currentIndex ? 'accent' : 'white',
        },
        bg: currentPage === currentIndex && 'accentSecondary',
        borderRadius: 1,
        color: 'white',
        fontSize: 3,
        transition: 'all 0.25s ease-in-out',
        px: 2,
      },
      paginatorOneOfTotal: {
        alignItems: 'center',
        color: 'white',
        display: 'flex',
        justifyContent: 'flex-end',
        p: 3,
      },
      paginatorOneOfTotalContainer: {
        mr: 3,
      },
    };
  };

  defaultStyle.defaultFormError = {
    color: 'error',
    mt: 'md',
    textAlign: 'center',
  };

  defaultStyle.defaultCustomSpinner = (barColor) => ({
    customSpinnerBox: {
      display: 'flex',
      justifyContent: 'center',
      py: 7,
      width: '100%',
    },
    barLoader: {
      color: barColor,
    },
  });

  defaultStyle.defaultTable = (
    tableSx = {},
    fixedSx = {},
    onRowClick = {},
    rowIsActive = {},
    cellSx = {}
  ) => ({
    table: {
      borderCollapse: 'collapse',
      borderRadius: 2,
      fontSize: 3,
      lineHeight: '1.4em',
      width: '100%',
      ...tableSx,
    },
    tableHeaderBox: {
      borderBottomStyle: 'solid',
      borderColor: 'accent',
      borderWidth: '3px',
    },
    tableExpandedSectionHeader: {
      '&.fixed': fixedSx,
      borderRadius: 2,
      fontSize: 3,
      fontWeight: 'bold',
      p: 5,
      textAlign: 'center',
    },
    tableHeader: {
      '&.fixed': fixedSx,
      color: 'accent',
      fontSize: 3,
      fontWeight: 'bold',
      p: 5,
      textAlign: 'left',
    },
    trSx: {
      '&:hover': {
        bg: onRowClick && !rowIsActive ? 'accentLight' : 'gray.5',
        color: onRowClick && !rowIsActive ? 'white' : null,
      },
      bg: rowIsActive ? 'accent' : 'inherit',
      color: rowIsActive ? 'white' : 'text.subtle',
      cursor: onRowClick ? 'pointer' : 'default',
      transition: 'all 0.25s ease-in-out',
    },
    tdSx: {
      '&.fixed': fixedSx,
      '&:hover': {
        bg: `${(p) => lighten(0.2, `${p.theme.colors.accent}`)}`,
      },
      fontSize: 3,
      px: 5,
      py: 4,
      transition: 'all 0.25s',
      ...cellSx,
    },
    emptyTableData: {
      alignItems: 'center',
      color: 'accent',
      display: 'flex',
      fontSize: 6,
      fontWeight: 'bold',
      height: '200px',
      justifyContent: 'center',
    },
  });

  defaultStyle.defaultMultiSelectDropDown = {
    iconStyle: {
      '&:hover': { fill: 'accentDark' },
      fill: 'accent',
      transition: 'fill 0.2s',
      width: '1rem',
    },
  };

  // eslint-disable-next-line no-unused-vars
  defaultStyle.defaultModal = (style, title, titleNode) => {
    return {
      simpleModalStyle: {
        content: {
          border: 'none',
          bottom: 'auto',
          boxShadow: shadows[3],
          left: '50%',
          margin: 0,
          overflow: 'visible',
          padding: 0,
          right: 'auto',
          top: '50%',
          ...style.content,
        },
        overlay: {
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          ...style.overlay,
        },
      },
      reactModalStyle: {
        content: {
          border: 'none',
          borderRadius: radii[1],
          bottom: 'auto',
          boxShadow: shadows[3],
          left: '50%',
          maxWidth: sizes.maxWidths.form,
          overflow: 'visible',
          padding: 0,
          right: 'auto',
          top: '50%',
          width: `calc(100% - ${space[5]} * 2`,
          ...style.content,
        },
        overlay: {
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          ...style.overlay,
        },
      },
      titleBox: {
        fontSize: [4, null, null, 5],
        fontWeight: 'bold',
        px: [5, null, null, 6],
        py: 4,
        zIndex: 1,
      },
      childrenBox: {
        height: 'auto',
        maxHeight: '50vh',
        overflow: 'auto',
        pb: 7,
        pt: 6,
        px: [5, null, null, 6],
      },
      footerBox: {
        borderColor: 'accent',
        borderTopStyle: 'solid',
        borderWidth: '1px',
        display: 'flex',
        justifyContent: 'flex-end',
        p: 5,
      },
    };
  };

  defaultStyle.defaultCarousel = (
    activeSlideIndex = null,
    index = null,
    onSlideClick = null,
    slideSx = {},
    slideBackground = () => {}
  ) => {
    return {
      carouselBox: {
        alignItems: 'center',
        display: 'flex',
        m: 3,
      },
      carouselSlide: {
        '&:hover': {
          boxShadow: (theme) => theme.shadows[2],
        },
        bg: slideBackground(activeSlideIndex, index),
        borderRadius: 2,
        boxShadow: (theme) => theme.shadows[6],
        color: activeSlideIndex >= index ? 'white' : 'black',
        cursor: onSlideClick ? 'pointer' : 'default',
        mx: 4,
        my: 5,
        p: 2,
        textAlign: 'center',
        transition: 'box-shadow 0.2s',
        width: '100%',
        ...slideSx,
      },
    };
  };

  defaultStyle.defaultDropDown = {
    dropDownOptionBox: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      verticalAlign: 'middle',
    },
    formatGroupLabelBox: {
      bg: 'accent',
      color: 'text.subtle',
      fontSize: 1,
      fontWeight: 'bold',
      left: '-0.8rem',
      letterSpacing: 1,
      mb: '0.32rem',
      mr: '-1.6rem',
      mt: '-0.75rem',
      position: 'relative',
      px: '1rem',
      py: 3,
      textAlign: 'center',
    },
    optionLabelBox: {
      alignItems: 'flex-start',
      display: 'flex',
      justifyContent: 'space-between',
      mb: 1,
    },
    optionPillBox: {
      flex: 'auto',
      mr: 2,
      wordBreak: 'break-all',
    },
    pillBox: {
      bg: 'orange',
      borderRadius: 1,
      color: 'white',
      flex: 'none',
      fontSize: 1,
      p: 1,
    },
    subTextBox: {
      color: 'accent',
      fontSize: 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };

  defaultStyle.getDropDownOverrideStyles = ({ error, name, theme }) => {
    const getBorderColor = ({ isFocused } = {}) => {
      if (isFocused) {
        return themeVariantToValue(
          theme,
          'colors',
          'inputs.primary.&:focus.borderColor'
        );
      }
      if (error) return theme.colors.error;
      return themeVariantToValue(theme, 'colors', 'inputs.primary.borderColor');
    };

    const getBorder = ({ isFocused } = {}) => {
      const borderWidth = themeVariantToValue(
        theme,
        'sizes',
        'inputs.primary.borderWidth'
      );

      const borderColor = getBorderColor({ isFocused });
      return `${theme.variants.inputs.primary.borderStyle} ${borderWidth} ${borderColor}`;
    };

    const borderRadius = themeVariantToValue(
      theme,
      'radii',
      'inputs.primary.borderRadius'
    );

    const backgroundColor = themeVariantToValue(
      theme,
      'colors',
      'inputs.primary.bg'
    );

    // const placeholderColor = themeVariantToValue(
    //   theme,
    //   'colors',
    //   'inputs.primary.&::placeholder.color'
    // );

    const paddingX = themeVariantToValue(theme, 'space', 'inputs.primary.px');
    const paddingY = themeVariantToValue(theme, 'space', 'inputs.primary.py');
    const height = `calc(${paddingY} * 2 + ${theme.lineHeights[1]})`;

    return {
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      clearIndicator: (base) => {
        return {
          ...base,
          '&:hover': { color: theme.colors.error, transform: 'scale(1)' },
          transform: 'scale(0.8)',
          transition: 'color 0.5s, transform 0.5s',
        };
      },
      container: (base) => ({ ...base, flex: 1 }),
      control: (base, { isFocused }) => ({
        ...base,
        '&:hover': { border: getBorder({ isFocused }) },
        '> div': {
          height: 'calc(100% - 4px)',
          margin: '2px 0',
          overflow: 'hidden',
        },
        backgroundColor: config.canadaEnv && name === 'versionId' ? '#D5F5F3' : '#FFFFFF',
        border: getBorder({ isFocused }),
        borderRadius,
        boxShadow: 0,
        height,
        minHeight: height,
        padding: 0,
      }),
      dropdownIndicator: (base) => ({ ...base, padding: `0 ${paddingX}` }),
      indicatorSeparator: (base) => ({
        ...base,
        backgroundColor: getBorderColor(),
      }),
      input: () => ({
        position: 'absolute',
      }),
      multiValue: (base) => ({
        ...base,
        '+ *[class*="-Input"]': {
          position: 'static',
        },
      }),
      option: (base, state) => {
        const { isMulti, isSelected } = state;
        if (isMulti) {
          return {
            ...base,
            '&:hover': {
              backgroundColor: theme.colors.accent,
              color: theme.colors.white,
            },
            backgroundColor: 'white',
            color: isSelected
              ? theme.colors.accentDark
              : theme.colors.primaryDark,
            wordBreak: 'break-word',
          };
        }
        return {
          ...base,
          wordBreak: 'break-word',
        };
      },
      placeholder: () => ({
        color: theme.colors.textPrimary,
      }),
      singleValue: (base) => ({
        ...base,
        maxWidth: 'calc(100% - 1.5rem)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }),
      valueContainer: (base) => ({
        ...base,
        border: 0,
        borderRadius,
        padding: `0 ${paddingX}`,
      }),
    };
  };

  return {
    baseFontSizesForBreakpoint,
    breakpoints,
    colors,
    defaultStyle,
    fontSizes,
    fontWeights,
    fonts,
    gradients,
    letterSpacings,
    lineHeights,
    radii,
    shadows,
    sizes,
    space,
    variants,
    zIndices,
    MGA,
  };
};

/* theme */
export default defaultTheme;
