import Box from '@basecomponents/Box';
import Button from '@basecomponents/Button';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import CustomRoundSpinner from '../CustomRoundSpinner';
import CustomSpinner from '../CustomSpinner';
import EditAccessRightsContext from '../../Pet/EditAccessRights/Context';
import Icon from '../../Icon';

/**
 * @category BaseComponents
 * @param {string} bg
 * @param {string} icon
 * @param {string} label
 * @param {function} onClick
 * @param {Object} buttonSx
 * @param {boolean} visible
 * @param {boolean} isLoading
 * @param {boolean} isDisabled
 * @param {string} permission
 * @param {boolean} submitting
 * @param {Object} iconSx
 * @param {boolean} useSecondarySpinner
 * @param {Object} spinnerSx
 * @param {*} rest
 * @returns {any}
 */
const ToolbarButton = ({
  bg,
  icon,
  label,
  onClick,
  buttonSx,
  visible,
  isLoading,
  isDisabled,
  permission,
  submitting,
  iconSx,
  useSecondarySpinner,
  spinnerSx,
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  const { defaultStyle = {} } = theme;
  const ear = useContext(EditAccessRightsContext);
  const earVisible = permission ? ear.isVisible(permission) : true;
  const earEditable = permission ? ear.isEditable(permission) : true;

  const iconOnly = !label && icon;
  const iconOnlySx = iconOnly
    ? {
        borderRadius: '50%',
        height: '30px',
        width: '30px',
      }
    : {};

  const loadingSpinner = () => {
    if (iconOnly || useSecondarySpinner) {
      return (
        <CustomRoundSpinner barColor={theme.colors.white} {...spinnerSx} />
      );
    }
    return <CustomSpinner barColor={theme.colors.white} {...spinnerSx} />;
  };

  const { buttonStyle = {}, iconStyle = {} } =
    defaultStyle.defaultToolbarButton(
      buttonSx,
      iconOnlySx,
      bg,
      iconSx,
      iconOnly
    );

  return (
    <>
      {earVisible && visible ? (
        <Button
          disabled={!earEditable || isDisabled}
          onClick={onClick}
          submitting={submitting}
          sx={{ ...buttonStyle }}
          {...rest}
        >
          {isLoading ? (
            loadingSpinner()
          ) : (
            <>
              {icon && <Icon mr="sm" svg={icon} sx={{ ...iconStyle }} />}
              <Box
                display={
                  icon
                    ? {
                        md: 'flex',
                      }
                    : {}
                }
              >
                {label}
              </Box>
            </>
          )}
        </Button>
      ) : null}
    </>
  );
};

ToolbarButton.defaultProps = {
  bg: 'primary',
  buttonSx: {},
  icon: '',
  iconSx: {},
  isDisabled: false,
  isLoading: false,
  label: '',
  onClick: () => {},
  permission: '',
  spinnerSx: {},
  submitting: false,
  useSecondarySpinner: false,
  visible: true,
};

ToolbarButton.propTypes = {
  bg: PropTypes.string,
  buttonSx: PropTypes.shape({}),
  icon: PropTypes.string,
  iconSx: PropTypes.shape({}),
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  permission: PropTypes.string,
  spinnerSx: PropTypes.shape({}),
  submitting: PropTypes.bool,
  useSecondarySpinner: PropTypes.bool,
  visible: PropTypes.bool,
};

export default ToolbarButton;
