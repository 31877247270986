import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import frTranslations from '@src/common/translations/fr.json'
import enTranslations from '@src/common/translations/en.json'
import caEnTranslations from '@src/common/translations/ca-en.json'

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: process.env.NODE_ENV === 'development',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            caEn: {
                translation: caEnTranslations
            },
            en: {
                translation: enTranslations
            },
            fr: {
                translation: frTranslations
            },
        }
    });

export default i18n;
