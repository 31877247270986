import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { PuffLoader } from 'react-spinners';
import Box from '@basecomponents/Box';
import { ThemeContext } from 'styled-components';

/**
 * @category BaseComponents
 * @param {string} barColor
 * @returns {React.FC}
 */
const CustomRoundSpinner = ({ barColor, ...props }) => {
  const { defaultStyle = {} } = useContext(ThemeContext);
  const { outerBox = {}, puffLoader = {} } =
    defaultStyle.defaultCustomRoundSpinner(barColor);
  return (
    <Box {...outerBox} {...props}>
      <PuffLoader {...puffLoader} />
    </Box>
  );
};

CustomRoundSpinner.propTypes = {
  barColor: PropTypes.string.isRequired,
};

export default CustomRoundSpinner;
