import Box from '@basecomponents/Box';
import axios from 'axios';
import jstz from 'jstimezonedetect';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';

/**
 * HOC for notifying about time drift
 * @category BaseComponents
 * @param {React.ReactNode} children
 * @param {string} variation
 * @returns {React.FC}
 */

const SystemTimeCheck = () => {
  const [time, setTime] = useState('');
  const [systemTime, setSystemTime] = useState({});
  const [absDifference, setAbsDifference] = useState(-1);
  const [difference, setDifference] = useState('');
  const [timezone, setTimezone] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const baseRequestString = 'https://worldtimeapi.org/api/timezone';
    const fetchTime = async () => {
      try {
        setLoading(true);
        const timezone = jstz.determine();
        // https://bugs.chromium.org/p/chromium/issues/detail?id=580195
        const name = timezone.name().replace('Calcutta', 'Kolkata');
        const requestString = `${baseRequestString}/${name}`;
        const response = await axios.get(requestString);
        const time = await response.data.datetime;
        setTimezone(name);
        setTime(time);
        setSystemTime(Moment.now());
        setDifference(
          Moment.duration(Moment(time).diff(systemTime)).humanize()
        );
        setAbsDifference(Math.abs(Moment(time).diff(systemTime)));
        if (document.visibilityState === 'visible') {
          setTimeout(() => {
            setShowDialog(true);
          }, 3000);
        } else {
          setShowDialog(false);
        }
        setLoading(false);
      } catch (err) {
        // noop
        setLoading(false);
      }
    };
    const interval = setInterval(
      () => fetchTime(),
      absDifference > 300000 || absDifference === -1 ? 1000 : 240000
    );
    return () => {
      clearInterval(interval);
    };
  }, [absDifference]);

  return (
    <>
      {!loading && absDifference > 300000 && showDialog && (
        <Modal
          footerSx={{
            p: 0,
          }}
          isOpen={absDifference > 300000 && showDialog && !loading}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box as="h2" sx={{ color: 'error' }}>
              Error!
            </Box>
            <Box
              as="h4"
              sx={{
                mt: 3,
                textAlign: 'justify',
              }}
            >
              Unfortunately, the system timed out! If this continues to occur,
              please check that your computer clock is displaying the correct
              time.
            </Box>
            <Box
              as="h4"
              sx={{
                mt: 1,
                textAlign: 'justify',
              }}
            >{`This system's clock has a difference of ${difference} from the correct time in ${timezone}. The correct time is: `}</Box>
            <Box as="h1" sx={{ mt: 3 }}>
              {Moment(time).format('hh:mm:ss A')}
            </Box>
            <Box
              as="h4"
              sx={{
                mt: 3,
                textAlign: 'justify',
              }}
            >
              Please correct your system clock. If you are still experiencing
              issues, please contact the IAIC administrator, 844-738-4242 or
              IAIC.pets@independenceamerican.com
            </Box>
            <Box
              as="h4"
              sx={{
                color: 'error',
                mt: 1,
                textAlign: 'justify',
              }}
            >
              Once you have corrected your system&apos;s clock, please refresh
              the page.
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default SystemTimeCheck;

SystemTimeCheck.propTypes = {
  children: PropTypes.shape({}).isRequired,
};
